//  ---------------------------------------
//  Paths
//  ---------------------------------------

$path-fonts:              '../fonts' !default;
$path-fontawesome:        '../fonts/fontawesome-pro' !default;
$path-img:                '../img' !default;

//  ---------------------------------------
//  Layout
//  ---------------------------------------

// Grid and gutters
$layout-gutter-small: 16px;
$layout-gutter-medium: 32px;

//  ---------------------------------------
//  Colors
//  ---------------------------------------

// Universal
$color-white: rgb(255,255,255);
$color-black: rgb(0,0,0);

// Color palette - primary
$color-precision-primary-base: #10416A;
$color-precision-primary-grey: #567996;
$color-precision-primary-navy: #052642;
$color-precision-primary-bright: #157EC3;

$colors-precision:(
  'base'   :  $color-precision-primary-base,
  'grey'   :  $color-precision-primary-grey,
  'navy'   :  $color-precision-primary-navy,
  'bright' :  $color-precision-primary-bright
);

// Button colors
$color-secondary-main: #50575F;

// Greys
$color-grey-light-1: #EDEFF2;
$color-grey-light-2: #DEE5EB;
$color-grey-light-3: #C0C8D3;
$color-grey-dark-1: #737C88;
$color-grey-dark-2: #343A40;
$color-grey-dark-3: #24272B;

// Other colors
$color-green: #1A9156;
$color-yellow: #E6B201;
$color-red: #C82333;

// Color assignments
$color-text-main: $color-grey-dark-3;

//  ---------------------------------------
//  Icons
//  ---------------------------------------

$fa-font-path: $path-fontawesome;
$icon-folder-path: #{$path-img} + "/";

//  ---------------------------------------
//  Typography
//  ---------------------------------------

$type-size-base: 1rem;
$type-sizes: (
  12: 0.75rem,
  14: 0.875rem,
  16: $type-size-base,
  18: 1.125rem,
  20: 1.25rem,
  24: 1.5rem,
  28: 1.75rem,
  32: 2rem,
  36: 2.25rem,
  42: 2.625rem,
  48: 3rem,
  54: 3.375rem,
  60: 3.75rem,
  68: 4.25rem,
  76: 4.75rem,
  84: 5.25rem,
  92: 5.75rem
);

//  ---------------------------------------
//  Other interactions
//  ---------------------------------------

// Transitions
$base-transition-duration: 0.25s;
$base-transition-function: ease-in-out;
$base-transition: $base-transition-duration $base-transition-function;

//  ---------------------------------------
//  Bootstrap - settings
//  ---------------------------------------

// Enable the CSS grid
$enable-cssgrid: true;

// Border radius
$enable-rounded: false;
$enable-shadows: true;

//  ---------------------------------------
//  Bootstrap - overrides
//  ---------------------------------------

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
 xxl: 1400px
) !default;

// Max content widths
$container-max-widths: (
  sm: 544px,
  md: 720px,
  lg: 960px,
  xl: 1120px,
 xxl: 1320px
) !default;

// Gutters (should match the 8pt grid)
$grid-gutter-width: 2rem;

// Extend spacers
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
) !default;
$spacers: map-merge(
  (
    6: ($spacer * 3),
    7: ($spacer * 4.5),
    8: ($spacer * 6)
    ),
  $spacers
);

// Body
$body-bg: $color-white !default;
$body-color: $color-grey-dark-3;

// Borders
$border-width: 1px;

// Colors
$primary: $color-precision-primary-base;
$secondary: $color-secondary-main;
$success: $color-green;
$info: $color-precision-primary-bright;
$warning: $color-yellow;
$danger: $color-red;
$light: $color-grey-light-1;

// Darkened colors (for modals, toasts, etc)
$danger_dark: darken($danger, 5%);
$warning_dark: darken($warning, 10%);

// Box shadow
$box-shadow: 0 0.2rem 0.5rem rgba($color-black, 0.1);
$box-shadow-sm: 0 .125rem .25rem rgba($color-black, .075);

// Typography
$type-color-main: $color-grey-dark-3;
$font-family-base: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-size-base: $type-size-base;
$headings-font-weight: 400;
$font-size-lg: $font-size-base;
$small-font-size: map-get($type-sizes, 14);
$font-weight-lighter: 100 !default;

$h1-font-size: map-get($type-sizes, 42);
$h2-font-size: map-get($type-sizes, 32);
$h3-font-size: map-get($type-sizes, 28);
$h4-font-size: map-get($type-sizes, 24);
$h5-font-size: map-get($type-sizes, 20);
$h6-font-size: map-get($type-sizes, 16);

$headings-font-weight: 600 !default;

$text-muted: $color-grey-dark-1;

// Links
$link-color: $color-precision-primary-bright;
$link-decoration: underline;
$link-hover-decoration: none;


//  ---------------------------------------
//  Bootstrap components
//  ---------------------------------------

// Add Bootstrap utilities variable for later use
$utilities: ();

// Alert
$alert-border-scale: 0;
$alert-icon-weight: "fa-icon-light";

// Breadcrumb
$breadcrumb-font-size:              map-get($type-sizes, 14);
$breadcrumb-padding-y: 				0.75rem;
$breadcrumb-item-padding-x:			1.25rem;
$breadcrumb-divider-color:          $color-grey-light-3;
$breadcrumb-active-color:           $color-grey-dark-1;
//$breadcrumb-divider:                quote("/");
//$breadcrumb-divider-flipped:        $breadcrumb-divider;
$breadcrumb-margin-bottom:          0;
$precision_breadcrumb-wrapper-bg:	rgba($color-white, 0.85);

// Button
$input-btn-border-width: $border-width;
$input-btn-font-family: $font-family-base;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-line-height: calc(1.5rem - ($input-btn-border-width * 2));
$btn-padding-right: 3rem; // non-Bootstrap setting
// Large buttons
$btn-padding-y-lg: 0.75rem;
$btn-padding-x-lg: 1rem;
$btn-font-size-lg: $font-size-base;
// Small buttons
$btn-padding-x-sm: 1rem;
$btn-font-size-sm: $font-size-base;
// Icon and icon-only buttons
$btn-icon-margin-left: calc(2.5 * $btn-padding-x);
$btn-icononly-size-lg: 3rem;
$btn-icononly-size-md: 2.5rem;
$btn-icononly-size-sm: 2rem;

$btn-icononly-font-size-lg: map-get($type-sizes, 20);

// Dropdown button
$caret-width: 0.35rem;
$caret-vertical-align: $caret-width * .85;
$caret-spacing: 0.75rem;


// Card
$card-bg: $color-white;
$card-box-shadow: $box-shadow;
$card-border-width: 0;

// Caret
$enable-caret: false;

// Display
// Addding "contents" to Bootstrap utility responsive classes
  $utilities: map-merge(
    $utilities,
    (
      "display": (
      property: display,
      class: d,
      responsive: true,
      values: contents inline inline-block block grid inline-grid table table-row table-cell flex inline-flex none
      )
    )
  );

// Forms

$form-text-font-size: $font-size-base;
$form-label-font-size: map-get($type-sizes, 14);
$form-label-color: $color-grey-dark-2;
$input-bg: $color-grey-light-1;

$input-border-width: 1px;
$input-border-radius: 0;
$precision_input-border-color: lighten($color-grey-dark-1, 25%);
$input-border-color: $precision_input-border-color;
$input-border-color-hover: darken($input-border-color, 15%);
$input-focus-border-color: $primary;
$input-border-width: 2px;

$input-placeholder-color: $color-grey-dark-1;

$input-line-height: 2;
$input-padding-x: 0.5rem;
$input-padding-y: 0.25rem;

$input-disabled-color: $color-grey-light-3;
$input-disabled-bg: $color-grey-light-1;
$input-disabled-border-color: lighten($input-border-color, 25%);

$input-group-addon-padding-x: 1rem;
$input-group-addon-border-color: $input-border-color;
$input-group-addon-bg: $color-grey-light-2;

$form-feedback-font-size: map-get($type-sizes, 14);
$form-select-disabled-color: $input-disabled-color;
$form-check-input-width: 1.5em;
$form-check-margin-bottom: 0;
$precision_form-check-input-border-color-hover: 1px solid rgba($color-black, .4);
$form-switch-width: 3em;
$precision_form-switch-height: 1.5em;
$form-switch-border-radius: $form-switch-width;
$precision_form-switch-input-checked-bg-color: $success;


// Modal
$modal-backdrop-opacity: .6;
$precision_modal-header-bg: $primary;
$precision_modal-header-text: $color-white;

// Close button
$btn-close-color: $color-white;
$btn-close-opacity: 0.65;
$btn-close-hover-opacity: 0.8;

// Pagination
$pagination-color: $primary;
$pagination-padding-x: 1rem;
$pagination-padding-x-sm: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

// Tables

$table-cell-padding-y: 1rem;
$table-active-bg: $color-grey-light-1;
$precision_table_light_bg: $color-grey-light-2;
$precision_table_dark_bg: $color-grey-dark-2;

// Toast
$toast-header-color: $color-white;
$toast-header-background-color: $primary;
$toast-background-color: rgba($color-white, .95);
$toast-zindex: 1090 !default;

//  ---------------------------------------
//  Precision Design components
//  ---------------------------------------

// Header
$header-height: 60px;
$header-bg: $color-white;
$header-border-bottom-height: 4px;
$header-border-bottom-height-sm: 2px;
$header-border-bottom-defaultcolor: $primary;
$header-border-bottom-defaultcolor-sm: $color-grey-dark-1;

$header-title-size: map-get($type-sizes, 24);
$header-title-size-sm: map-get($type-sizes, 18);

// Footer
//$footer-height: 64px;
$footer-bg: $color-white;

// Tabs
$tabs-height: 48px;
$tabs-padding-x: 2rem;
$tabs-padding-y: 0.5rem;

$tabs-border-bottom-width: 3px;
$tabs-border-bottom-color-active: $primary;
$tabs-border-bottom-color-default: $color-grey-light-3;
$tabs-border-bottom-color-focus: $color-grey-dark-1;

$tabs-text-color-default: $body-color;
$tabs-text-color-active: $primary;
$tabs-text-color-disabled: $color-grey-light-3;

// Tooltips
$tooltip-padding-x: $spacer * 0.75;
$tooltip-padding-y: $spacer * 0.5;
$tooltip-opacity: 0.85;
$tooltip-arrow-width: 1rem;
$tooltip-arrow-height: 0.5rem;

// Plan table
$precision_plantable-indicator-border-width: 0.25rem;

// Add Bootstrap utility responsive classes
  $utilities: map-merge(
    $utilities,
    (
      "font-size": (
      property: font-size,
      class: type,
      responsive: true,
      values: $type-sizes
      )
    )
  );