.btn-tile
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	outline: none;
	border: solid 1px $color-grey-light-3;
	background: none;
	text-align: center;
	text-decoration: none;
	padding: 1rem;
	color: $color-grey-dark-1;
	box-shadow: none;
	transition: background-color $base-transition, border-color $base-transition, color $base-transition, box-shadow $base-transition;
	
	*[class^='fa-']
	{
		margin-bottom: 0.5rem;
		font-size: 1.5rem;
	}
	
	&:hover
	{
		background-color: $primary;
		border-color: $primary;
		color: $color-white;
		transition: background-color $base-transition, border-color $base-transition, color $base-transition, box-shadow $base-transition;
	}
	
	// Secondary
	&.btn-tile-secondary
	{
		&:hover
		{
			background-color: $secondary;
			border-color: $secondary;
		}
	}
	
	// Outline only
	&.btn-tile-outline
	{
		&:active:not(:hover), 
		&:focus:not(:hover)
		{
			border-color: rgba($color-grey-dark-1, 0.75);
			box-shadow: $box-shadow;
		}
		&:hover
		{
			background: none;
			border-color: $color-grey-dark-1;
			box-shadow: $box-shadow;
		}
	}
}