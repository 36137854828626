// PD Plan table
.pd-plan-table-header
{
	background-color: $precision_table_dark_bg;
	--bs-table-bg: $precision_table_dark_bg;
	border-color: $precision_table_dark_bg;
	color: $color-white;
}
.pd-plan-table-row
{
	border-bottom: solid 1px $color-grey-light-3;
	
	&:last-of-type
	{
		border-bottom: none;
	}
	
	// Indicators
	
	&[class*="pd-plan-table-indicator"]
	{
		border-top: solid $precision_plantable-indicator-border-width $success;
		
		@include media-breakpoint-up(lg)
		{
			border-top: none;
			border-left: solid $precision_plantable-indicator-border-width $success;
			
			.pd-plan-table-col
			{
				padding-left: 1rem;
			}
			.pd-plan-col:first-of-type
			{
				margin-right: -$precision_plantable-indicator-border-width;
			}
		}
	}	
	&.pd-plan-table-indicator-success
	{
		border-top-color: $success;
		
		@include media-breakpoint-up(lg)
		{
			border-left-color: $success;
		}
	}
	&.pd-plan-table-indicator-warning
	{
		border-top-color: $warning;
		
		@include media-breakpoint-up(lg)
		{
			border-left-color: $warning;
		}
	}
	&.pd-plan-table-indicator-danger
	{
		border-top-color: $danger;
		
		@include media-breakpoint-up(lg)
		{
			border-left-color: $danger;
		}
	}
	&.pd-plan-table-indicator-grey
	{
		border-top-color: $color-grey-dark-1;
		
		@include media-breakpoint-up(lg)
		{
			border-left-color: $color-grey-dark-1;
		}
	}
}

// Secondary indicators

[class*="pd-plan-table-indicator"][class*="pd-plan-table-secondary-indicator"]
{
	&:before
	{
		content: "";
		width: $precision_plantable-indicator-border-width;
		height: 50%;
		position: absolute;
		bottom: 0;
		left: -$precision_plantable-indicator-border-width;
	}
}
[class*="pd-plan-table-secondary-indicator-success"]:before
{
	background-color: $success;
}
[class*="pd-plan-table-secondary-indicator-warning"]:before
{
	background-color: $warning;
}
[class*="pd-plan-table-secondary-indicator-danger"]:before
{
	background-color: $danger;
}

// Plan table columns and card

.pd-plan-table-col
{
	padding: 1rem 1rem 1rem 1.25rem;
	
	@include media-breakpoint-up(lg)
	{
		align-self: center;
	}
	@include media-breakpoint-up(xl)
	{
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}	
}
.pd-plan-table-card
{	
	@extend .card;
	flex-direction: unset;
}

// Element specific
.pd-plan-table-input-main
{
	@include media-breakpoint-down(lg)
	{
		position: absolute;
    	top: -1rem;
    	padding: 0;
    	left: -0.5rem;
	}
}

// PD Plan Data table
.pd-plan-datatable
{
	display: grid;
	grid-template-rows: repeat(1, 1fr);
		
	.pd-plan-datatable-row
	{
		display: grid;
		grid-template-columns: repeat(2, minmax(200px, 1fr));
		row-gap: 1rem;
		column-gap: 0;
		
		background-color: $color-grey-light-2;
		border-bottom: solid 1px $color-grey-light-3;
		
		&:first-of-type
		{
			border-top: solid 1px $color-grey-light-3;
		}
		
		&:last-of-type
		{
			@include media-breakpoint-up(lg)
			{
				border-bottom: none;
			}
		}
	}
	.pd-grid-table-col
	{
		padding: 0;
	}
}
.pd-plan-table-label
{
	color: $color-grey-dark-1;
	@include type-size(16);
	
	@include media-breakpoint-up(lg)
	{
		display: none;
	}
}
.pd-datatable-label
{
	padding: 0.25rem 0.75rem;
	font-weight: 700;
	text-align: right;	
}
.pd-datatable-value
{
	padding: 0.25rem 0.75rem;
	background-color: rgba($color-white, 0.5);
}

.pd-plan-alert
{
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 1.5rem;
	padding: 0.75rem 1rem;
	background-color: #A86700;
	color: $color-white;
	
	@include media-breakpoint-up(lg)
	{
		margin-bottom: 0;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		margin-left: -0.25rem;
		// Allow alert to span the entire row width
    	box-sizing: content-box;
    	padding-right: 0;
    	max-width: calc(100% - 0.75rem);
	}
	
	&:before
	{
		content: "";
		position: absolute;
		bottom: -0.75rem;
		left: 2rem;
		width: 0; 
		height: 0; 
		border-left: 0.75rem solid transparent;
		border-right: 0.75rem solid transparent;
		border-top: 0.75rem solid #A86700;
		
		@include media-breakpoint-up(lg)
		{
			top: -0.65rem;
			bottom: auto;
			border-top: none;
			border-bottom: 0.75rem solid #A86700;
		}
	}
}

// PD options buttons
.pd-plan-options
{
	max-width: 400px;
	margin: 0.5rem auto 0;
	border: solid 1px $color-grey-light-3;
	background-color: $color-white;
	box-shadow: $box-shadow;
	
	@include media-breakpoint-up(lg)
	{
		width: 400px;
		position: absolute;
		z-index: 100;
		top: 2rem;
		right: 1rem;		
	}
}

.pd-plan-button-options
{
	@include media-breakpoint-up(lg)
	{
		position: relative;
	}
}