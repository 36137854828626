// Precision Design styles
.btn
{
	text-align: left;
	padding-right: $btn-padding-right;
	
	&.btn-icon
	{
		display: flex;
		position: relative;
        justify-content: space-between;
		align-items: center;
		
		*[class^='fa-']
		{
            position: absolute;
            right: $btn-padding-x;
			margin-left: $btn-icon-margin-left;
		}
	}
	&.btn-icon-only
	{
		width: $btn-icononly-size-md;
		height: $btn-icononly-size-md;
		display: flex;
		justify-content: center;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
		
		&.btn-lg
		{
			width: $btn-icononly-size-lg;
			height: $btn-icononly-size-lg;
			font-size: $btn-icononly-font-size-lg;
		}
		&.btn-sm
		{
			width: $btn-icononly-size-sm;
			height: $btn-icononly-size-sm;
		}
	}	
}
.btn-ghost,
.btn-ghost-primary
{
	@extend .btn-light;
	--#{$prefix}btn-bg: transparent;
	background-color: rgba(0,0,0,0);
	box-shadow: none;
	border-color: transparent;
	color: $primary;
}
.btn-ghost-secondary
{
	@extend .btn-ghost;
	color: $secondary;
}

// Bootstrap override for focus state

.btn
{
	&:focus-visible 
	{
		color: var(--#{$prefix}btn-hover-color);
		@include gradient-bg(var(--#{$prefix}btn-hover-bg));
		border-color: var(--#{$prefix}btn-hover-border-color);
		outline: 0;
		@if $enable-shadows
		{
			@include button-focus();
		}
		@else
		{
			@include button-focus();
		}
	}
}

// Custom override - Success button
.btn.btn-success
{
	@include button-variant(
        $success,
        $success,
        $hover-background: shade-color($success, $btn-hover-bg-shade-amount),
        $hover-border: shade-color($success, $btn-hover-border-shade-amount),
        $active-background: shade-color($success, $btn-active-bg-shade-amount),
        $active-border: shade-color($success, $btn-active-border-shade-amount)
      );
	color: $color-white;
}

// Working style
.btn.btn-working
{
    position: relative;
	@extend .btn-icon;
	
	// Replace any existing icon
	*[class^='fa-']
	{
		@extend .fa-spin-pulse;
		@extend .fa-solid;
		
		&:before
		{
			content: unquote("\"#{ $fa-var-spinner }\"");
		}
	}
	
	// Add icon if necessary
	&:after
	{
		width: 16px;
		height: 16px;
        position: absolute;
        right: 1rem;
		@extend .fa-spin-pulse;
		@extend .fa-solid;
		content: unquote("\"#{ $fa-var-spinner }\"");
		margin-left: 2rem;
	}
}
.btn-reset
{
	margin: 0;
	padding: 0;
	outline: none;
	border: none;
	box-shadow: none;
	background: none;
}
.btn-plantable-tooltip
{
	background-color: $color-white;
    padding: 0.25rem;
    border: solid 1px $color-grey-light-3;
	box-shadow: $box-shadow;
	
	@include media-breakpoint-up(lg)
	{
		background: none;
		padding: 0;
		border: none;
		box-shadow: none;
	}
}
// Remove text-decoration on disabled btn-link
.btn-link:disabled
{
	text-decoration: none;
}