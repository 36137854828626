.footer
{
	border-top: solid 1px $color-grey-light-3;
}
.footer-content
{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $header-bg;
}
.footer-logo-product-wrapper
{
	@include media-breakpoint-up(md)
	{
		border-left: solid 1px $color-grey-light-3;
	}
}
.footer-logo-product
{
	width: 160px;
	height: auto;
}
.footer-info-list
{
	@include list-reset();
	@include type-size(12);
	
	.label
	{
		color: $color-grey-dark-1;
	}
}