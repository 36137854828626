.toast
{
	.toast-header
	{
		&.success
		{
			background-color: $success;
		}
		&.danger
		{
			background-color: $danger_dark;
		}
		&.warning
		{
			background-color: $warning_dark;
		}
	}
}
// Bootstrap bug
.toast-container
{
	z-index: $toast-zindex;
}