.nav-main
{
	width: 100%;
	position: absolute;
	right: 0;
	top: 2px;
	z-index: 100;
	visibility: hidden;
	opacity: 0;
	z-index: -100;
	transition: visibility $base-transition, opacity $base-transition, z-index 0.5s linear;
	
	@include media-breakpoint-up(md)
	{
		right: 2px;
	}
}
body.nav-active
{
	@include media-breakpoint-down(sm)
	{
		.header
		{
			height: auto;
		}
		.nav-main
		{
			position: static;
		}
	}
	.nav-main
	{
		visibility: visible;
		opacity: 1;
		z-index: 100;
		transition: visibility $base-transition, opacity $base-transition;
	}
}
.nav-main-list
{
	width: 100%;
	background-color: $color-white;
	border: solid 1px $color-grey-dark-1;
	box-shadow: $box-shadow;
	@include list-reset();
	
	&:before,
	&:after
	{
		content: "";
		position: absolute;
		top: -10px;
		right: 18px;
		width: 0; 
		height: 0; 
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid $color-grey-dark-2;
		
		@include media-breakpoint-up(sm)
		{
			right: 8px;
		}
	}
	&:after
	{
		border-bottom: 10px solid $color-white;
		z-index: 200;
		top: -9px;
	}
	
	ul,ol
	{
		@include list-reset();		
	}
	& > li
	{
		border-bottom: solid 1px $color-grey-light-3;
	}
	button
	{
		background: none;
		border: none;
		outline: none;
	}
	a
	{
		color: $color-grey-dark-2;
		text-decoration-style: solid;
		text-decoration-thickness: 1px;
		text-decoration-color: $color-grey-dark-2;
		transition: text-decoration-color $base-transition;
		
		&:hover
		{
			text-decoration-color: rgba($color-grey-dark-2, 0);
			transition: text-decoration-color $base-transition;
		}
	}
	a,button
	{
		display: block;
		width: 100%;
		padding: 1rem;
		text-align: left;
	}
	& > li > ul
	{
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		background-color: $color-grey-light-1;
		border-top: solid 1px $color-grey-light-3;
		
		@include media-breakpoint-up(md)
		{
			background: none;
			border-top: none;
		}
		
		a,button
		{
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
		}
	}
	// Links with the arrow to the left
	.link-icon
	{
		margin-right: 1rem;
	}
	.nav-main-section-heading
	{
		display: flex;
		justify-content: space-between;
		align-items: center;
		text-transform: uppercase;
		color: $color-grey-dark-3;
		font-weight: 700;
		
		.link-icon
		{
			transition: transform $base-transition;
		}
		
		&[aria-expanded="true"] .link-icon
		{
			transform: scaleY(-1);
			transition: transform $base-transition;
		}
		@include media-breakpoint-up(md)
		{
			padding-bottom: 0;
			pointer-events: none;
			
			.link-icon
			{
				display: none;
			}
		}
	}
	.nav-main-subsection
	{
		@include media-breakpoint-up(md)
		{
			display: block;
		}
	}
}