.header
{
	width: 100%;
	min-height: $header-height;
	background-color: $header-bg;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: $header-border-bottom-height;
	box-shadow: $box-shadow-sm;
	
	&.header-sticky
	{
		position: fixed;
		top: 0;
	}	
	.header-border
	{
		width: 100%;
		height: $header-border-bottom-height-sm;
		position: absolute;
		bottom: -#{$header-border-bottom-height-sm};
		background-color: $header-border-bottom-defaultcolor-sm;
		
		@include media-breakpoint-up(sm)
		{
			height: $header-border-bottom-height;
			bottom: -#{$header-border-bottom-height};
			background-color: $header-border-bottom-defaultcolor;
		}
	}
}
.header-spacer
{
    width: 100%;
    height: $header-height;
}
.header-content
{
	height: $header-height;
	display: flex;
	align-items: center;
}
.header-logo
{
	width: 16px;
	height: 100%;
	display: flex;
    justify-content: center;
    align-items: center;
	background-color: $primary;
	
	@include media-breakpoint-up(sm)
	{
		width: 96px;
		background: none;
		border-left: solid 1px $color-grey-light-2;
		border-right: solid 1px $color-grey-light-2;
	}
	
	@include media-breakpoint-up(md)
	{
		min-width: 200px;
		max-width: 224px;
	}
	
	& > img,
	& > svg
	{
		width: 75%;
		height: auto;
	}
}
.header-title
{
	margin: 0.1256rem 0 0 1rem;
	font-size: $header-title-size-sm;
	
	@include media-breakpoint-up(md)
	{
		margin-left: 1.5rem;
		font-size: $header-title-size;
	}
}
.header-utility
{
	margin-left: auto;
}
.header-nav-button
{
	.header-nav-button-icon
	{
		width: 24px;
		height: 24px;
		display: flex;
    	justify-content: center;
    	align-items: center;
		@include fa-icon-solid($fa-var-bars);
		@include type-size(20);
	}
	//&.active:not(.inactive) .header-nav-button-icon
	&[aria-expanded="true"] .header-nav-button-icon
	{
		width: 24px;
		height: 24px;
		display: flex;
    	justify-content: center;
    	align-items: center;
		@include fa-icon-solid($fa-var-times);
	}
}