@mixin hidden()
{
	position: absolute;
	left: -10000px;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
}
// Force a word/string wrap
@mixin word-wrap()
{
	overflow-wrap: break-word;
	word-wrap: break-word;
	-ms-word-break: break-all;
	word-break: break-all;
	word-break: break-word;
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}
@mixin overflow-ellipsis()
{
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

// Reset for most lists
@mixin list-reset()
{
	margin: 0;
	padding: 0;
	list-style: none;
	list-style-type: none;
	outline: none;

	& > li
	{
		margin: 0;
		padding: 0;
	}
}

// Grid elements to collapse on "full bleed"
@mixin bleed-grid()
{
	&[class^="container"]
    {
        padding-left: 0;
        padding-right: 0;
    }
    &.row,
	& > .row
    {
        margin-left: 0;
        margin-right: 0;
    }
    & > .row > [class^="col"],
	&.row > [class^="col"]
    {
        padding-left: 0;
        padding-right: 0;
    }
}

// Provide an easy way to access the type sizes
@mixin type-size($value)
{
	font-size: map.get($type-sizes, $value);
}

// Focus state for buttons
@mixin button-focus
{
	box-shadow: none !important;
	outline: 1px solid $color-white;
	outline-offset: -4px;
}