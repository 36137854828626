.tabs
{
	align-items: stretch;

	.nav-item
	{
		display: flex;
		z-index: 2;
	}

	.nav-link
	{
		min-height: $tabs-height;
		padding-left: $tabs-padding-x;
		padding-right: $tabs-padding-x;
		color: $tabs-text-color-default;
		text-align: center;
		line-height: calc($tabs-height - ($tabs-padding-y + $tabs-padding-y) - $tabs-border-bottom-width);
		background-color: transparent;
		border: none;
		border-bottom: solid $tabs-border-bottom-width $tabs-border-bottom-color-default;

		&.active
		{
			color: $tabs-text-color-active;
			border-bottom-color: $tabs-border-bottom-color-active;
		}
		&.disabled
		{
			color: $tabs-text-color-disabled;
		}

		&:hover
		{
			background-color: $color-grey-light-1;
		}
		&:focus
		{
			outline: none;
			border-bottom-color: $tabs-border-bottom-color-focus;
		}
	}

	.tab-icon
	{
		display: block;
		//@include type-size(20);
		margin-top: 0.25rem;
		margin-bottom: 0.25rem;
	}

	// Stretched tabs

	&.tabs-stretched
	{
		justify-content: space-between;

		.nav-item
		{
			flex: 1;
		    justify-content: space-between;
		}

		.nav-link
		{
			flex: 1 0 auto;
		}
	}

	// Extended bottom border
	&.tabs-extend-right,
	&.tabs-extend-left,
	&.tabs-extend-center
	{
		&:after
		{
			content: "";
			display: block;
    		width: 100%;
			height: 3px;
    		position: relative;
			border-bottom: solid $tabs-border-bottom-width $tabs-border-bottom-color-default;
    		bottom: $tabs-border-bottom-width;
    		z-index: 1;
		}
	}
	&.tabs-extend-left
	{
	    justify-content: flex-end;
	}
	&.tabs-extend-center
	{
	    justify-content: center;
	}
}
