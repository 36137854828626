// Precision Design styles
.alert
{
	background-color: $color-white;
	box-shadow: $box-shadow;
	color: $color-text-main;
	border-top-width: 0.5rem;
	border-right: none;
	border-bottom: none;
	border-left: none;
	
	// Precision additions
	display: flex;
	
	&.alert-primary
	{
		border-top-color: $color-precision-primary-bright;
	}
	
	.alert-icon
	{
		width: 2rem;
		height: 2rem;
		margin-right: 1.5rem;
		@include type-size(32);
		font-weight: $alert-icon-weight;
	}
	.alert-heading
	{
		font-weight: 700;
	}
	.alert-content
	{
		display: flex;
    	flex-direction: column;
    	justify-content: center;
		
		p:last-child
		{
			margin-bottom: 0;
		}
	}
}
@each $state, $value in $theme-colors
{
    // Override Bootstrap values for border-color
    .alert-#{$state}
    {
        --#{$prefix}alert-border-color: #{$value};
        border-color: var(--#{$prefix}alert-border-color);
        
        & .alert-icon
        {
            color: $value;
        }
    }
}
// Icons

.alert-primary .alert-icon
{
	@include fa-icon-regular($fa-var-circle-info);
}
.alert-success .alert-icon
{
	@include fa-icon-regular($fa-var-circle-check);
}
.alert-warning .alert-icon
{
	@include fa-icon-regular($fa-var-circle-exclamation);
}
.alert-danger .alert-icon
{
	@include fa-icon-regular($fa-var-triangle-exclamation);
}