.dropdown-rc
{
	position: relative;
	
	.dropdown-rc-button
	{
    	display: flex;
    	justify-content: space-between;
    	align-items: center;
		width: 100%;
		padding: 0.5rem 1rem;
		background-color: $color-white;
		border: solid 1px $color-grey-light-3;
		transition: box-shadow $base-transition;
		
		&:hover,
		&:focus
		{
			box-shadow: $box-shadow;
			transition: box-shadow $base-transition;
		}
		
		&:after
		{
			content: "\f078";
			font-family: "Font Awesome 6 Pro";		
			color: $color-grey-light-3;
			margin-left: 1rem;
		}
		
		&.main-button
		{
			min-height: 56px;
		}
	}
	
	.dropdown-rc-content
	{
		--#{$prefix}card-border-width: #{$card-border-width};
		--#{$prefix}card-border-color: #{$card-border-color};
		--#{$prefix}card-bg: #{$card-bg};
		min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
		width: 100%;
		position: absolute;
		left: 0;
		z-index: 100;
		word-wrap: break-word;
		background-color: var(--#{$prefix}card-bg);
		background-clip: border-box;
		border: var(--#{$prefix}card-border-width) solid var(--#{$prefix}card-border-color);
		box-shadow: $box-shadow;
		
		&.auto-hide
		{
			display: none;
		}
		
		&.min-width-450
		{
			@include media-breakpoint-up(md)
			{
				min-width: 450px;
				left: auto;
				right: 0;
			}
			@include media-breakpoint-up(lg)
			{
				right: auto;
				left: 0;
			}
		}
	}
	.dropdown-rc-image
	{
		width: auto;
		max-width: calc(100% - 56px);
		height: 100%;
	}
	.dropdown-rc-button:focus,
	.dropdown-rc-button.button-active
	{
		&:after
		{
			content: "\f077";
		}
	}
	.dropdown-rc-button:focus
	{
		& ~ .dropdown-rc-content.auto-hide
		{
			display: block;
		}
	}
	
	&.disabled
	{
		.dropdown-rc-button
		{
			pointer-events: none;
			opacity: 0.5;
			color: $color-grey-dark-1;
		}
	}
}
.dropdown-rc-content-list
{
	@include list-reset();
	
	li
	{
		display: flex;
		border-bottom: solid 1px $color-grey-light-3;
	}
	.dropdown-rc-list-button
	{
		width: 100%;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		padding: 0.5rem 1rem;
		margin: 0;
		border: none;
		outline: none;
		text-align: left;
		background-color: transparent;
		transition: background-color $base-transition;
		
		&:hover
		{
			background-color: lighten($color-grey-light-1, 2%);
			transition: background-color $base-transition;
		}
	}
}