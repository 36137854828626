.img_preview {
	width: 99px;
	height: 129px;
	background-color: #dadada;
	background-position: 50% 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border: solid 5px $white;
	box-shadow: $box-shadow;
	transition: box-shadow $base-transition;

	&.grid {
		width: 100%;
		height: 132px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;
		border: none;
		border-bottom: solid 1px $color-grey-light-3;
		background-position: 50% 0;
		box-shadow: none;

		@include media-breakpoint-up(md) {
			height: 144px;
		}

		@include media-breakpoint-up(lg) {
			height: 156px;
		}
	}
}

.btn_qs_preview:hover .img_preview {
	box-shadow: 0 0.2rem 0.5rem rgba($color-black, 0.25);
	transition: box-shadow $base-transition;
	transition: box-shadow $base-transition;
}

// override the material UI error border
.qs-start-date .Mui-error .MuiOutlinedInput-notchedOutline {
	border-color: #0000003b !important;
	border-style: solid !important;
}