.wizard-list
{
	// For list elements
	@include list-reset();
	display: flex;
	
	// Shows only the current step on a small screen
	&.hide-noncurrent-mobile
	{
		@include media-breakpoint-down(sm)
		{
			.wizard-step:not(.state-current)
			{
				display: none;
			}
		}
	}
}
.wizard-step
{
	flex: 1 1 0;
	display: flex;
	align-items: flex-start;
	padding-top: 1rem !important;
	@include type-size(14);
	border-top: solid 2px $color-grey-light-3;
	
	.wizard-step-icon
	{
		width: 1.5rem;
		height: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.5rem;
		color: $type-color-main;
		@include type-size(24);
		@include fa-icon-regular($fa-var-circle);
	}
	
	.wizard-step-label
	{
		padding-right: 1rem;
		line-height: 1.75;
	}
	
	// Current state
	&.state-current .wizard-step-icon
	{
		color: $primary;
		@include fa-icon-solid($fa-var-circle);
	}
	
	// Completed / past state
	&.state-complete
	{
		border-top-color: $primary;
		
		.wizard-step-icon
		{
			color: $success;
			@include fa-icon-regular($fa-var-circle-check);	
		}
	}
}