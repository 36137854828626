.modal-header
{
	background-color: $precision_modal-header-bg;
	color: $precision_modal-header-text;
	
	&.success
	{
		background-color: $success;
	}
	&.warning
	{
		background-color: $warning_dark;
	}
	&.danger
	{
		background-color: $danger_dark;
	}
}
