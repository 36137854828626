.card
{
	background-color: $card-bg;
	
	&.pd-card
	{
		border: none;
		
		@include media-breakpoint-up(sm)
		{
			box-shadow: $card-box-shadow;
		}
	}
}