// Text styles
html,body
{
	// font-family: $type-base-font;
	// font-size: $type-base-font-size-small;
	// font-weight: $type-base-font-weight;
	color: $color-text-main;
}

// Type sizes

@each $key, $value in $type-sizes
{
	.type-#{$key}
	{
		@include type-size($key);
	}
}
// Type weights
.type-thin
{
	font-weight: 100;
}
.type-regular
{
	font-weight: 400;
}
.type-bold
{
	font-weight: 700;
}
.type-black
{
	font-weight: 900;
}