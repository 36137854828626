.button-bottom-container-sticky
{
	@include media-breakpoint-up(lg)
	{
		position: sticky;
		bottom: 3.25rem;
		background-color: $body-bg;
		border-top: solid 1px $color-grey-light-3;
	}
}
.header
{
	z-index: 100;
}