.pod-table-hcp
{
    @include media-breakpoint-down(md)
    {
        display: flex !important;
        flex-direction: column;

        .tr
        {
            display: grid;
            grid-template-columns: 48px 1fr;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            
            .td
            {
                padding-top: 0;
                padding-bottom: 0;
                
                &:first-of-type
                {
                    grid-row: span 1000;
                }
                &:nth-child(2)
                {
                    padding-top: 0.5rem;
                }
                &:last-of-type
                {
                    padding-bottom: 0.5rem;
                }
            }
        }
    }
}
.pod-table-batchprint {
    overflow: visible !important;
}