// Standard text input fields
.form-control,
.form-select
{
	border-top: none;
	border-left: none;
	border-right: none;
	box-shadow: none;
	padding: calc($input-padding-y - 1px) $input-padding-x;
	
	&:focus,
	&.is-invalid:focus
	{
		box-shadow: none;
		
		& ~ .input-group-text
		{
			border-color: $input-border-color-hover;
		}
	}
	
	&:hover:not(:disabled):not(:focus):not([readonly]):not(.is-invalid)
	{
		border-color: $input-border-color-hover;
	}
	
	&.icon-search
	{
		padding-left: 2rem;
		background-image: url(#{$icon-folder-path}/icon_magnifying-glass-solid.svg);
		background-position: 8px 50%;
		background-repeat: no-repeat;
		background-size: 16px auto;
	}
}

.input-group-text
{
	border-top: none;
	border-left: none;
	border-right: none;
}

.disabled 
{
	filter: none;
	opacity: 0.5;
}

.form-label
{
	line-height: 1.142857142857143;
	line-height: calc($form-text-font-size/$form-label-font-size);
}

.form-check
{
	.form-check-input
	{
		margin-right: calc(16px - 2px);
		
		&:not(:checked)
		{
			transition: border $base-transition, box-shadow $base-transition;
		}
		&:hover:not(:checked)
		{
			border: $precision_form-check-input-border-color-hover;
			transition: border $base-transition, box-shadow $base-transition;
		}
	}
	
	&.form-switch
	{
		.form-check-input
		{
			height: $precision_form-switch-height;
			border-radius: $form-switch-border-radius;
			&:checked
			{
				background-color: $precision_form-switch-input-checked-bg-color;
				border-color: $precision_form-switch-input-checked-bg-color;
			}
		}
	}
}