.temp-user-dropdown-filters
{
    position: absolute;
    z-index: 5;
    min-width: 200px;
    background-color: $white;
    box-shadow: $box-shadow;
    margin-top: 40px;
    
}
.temp-user-dropdown-peruseroptions
{
    position: absolute;
    margin-top: 40px;
    z-index: 4;
    background-color: $white;
    border: solid 1px $color-grey-dark-3;
}