.pd-table
{
	.table-light
	{
		background-color: $precision_table_light_bg;
		--bs-table-bg: $precision_table_light_bg;
		border-color: $precision_table_light_bg;
		
		.btn-table
		{
			color: $color-text-main;
		}
	}
	
	.table-dark
	{
		background-color: $precision_table_dark_bg;
		--bs-table-bg: $precision_table_dark_bg;
		border-color: $precision_table_dark_bg;
		color: $color-white;
		
		.btn-table
		{
			color: color.scale($color-white, $lightness: -20%);
			
			&.active
			{
				color: $color-white;
			}
		}
	}
	// Override some Bootstrap styles that cause a background bug
	> :not(caption) > * > *
	{
		background-color: inherit;
	}
	.input-container 
	{
		height: 35px;
		padding-left: 5px;
		align-content: center;
	}
}
