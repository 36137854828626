.pd-visibility-show
{
	visibility: visible;
	z-index: 100;
	opacity: 1;
	transition: visibility $base-transition, z-index 0.4s ease, opacity $base-transition;
}
.pd-visibility-hide
{
	visibility: hidden;
	z-index: -100;
	opacity: 0;
	transition: visibility $base-transition, z-index 0.4s ease, opacity $base-transition;
}