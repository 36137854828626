.breadcrumb-wrapper
{
	background-color: $precision_breadcrumb-wrapper-bg;
}
.breadcrumb
{
	a
	{
		color: $color-grey-dark-2;
	}	
	
}
.breadcrumb-item
{
	display: flex;
	align-items: center;
	
	// The separator between breadcrumbs (by default, a forward-slash: "/")
	+ .breadcrumb-item
	{
		&::before
		{
			//content: var(--#{$prefix}breadcrumb-divider, escape-svg($breadcrumb-divider)) #{"/* rtl:"} var(--#{$prefix}breadcrumb-divider, escape-svg($breadcrumb-divider-flipped)) #{"*/"};
			content: unquote("\"#{ $fa-var-chevron-right }\"");
			@include fa-icon-regular($fa-var-chevron-right);
		}
	}
}