.footer
{
    width: 100%;
    margin-top: auto;
    border-top: solid 1px $color-grey-light-3;
    @include media-breakpoint-up(lg)
    {
        position: fixed;
        bottom: 0;
    }
}
.pre-footer
{
    width: 100%;
    height: 6rem;
    display: flex;
    align-items: center;
    background-color: $footer-prefooter-bg;
}
.pre-footer-img
{
    width: 164px;
    height: auto;
}