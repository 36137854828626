// Roboto Thin
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-display: auto;
	font-style: normal;
}
// Roboto Thin Italic
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-display: auto;
	font-style: italic;
}
// Roboto Regular
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-Regular.ttf') format('truetype');
	font-weight: 400;
	font-display: auto;
	font-style: normal;
}
// Roboto Regular Italic
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-Italic.ttf') format('truetype');
	font-weight: 400;
	font-display: auto;
	font-style: italic;
}
// Roboto Bold
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-Bold.ttf') format('truetype');
	font-weight: 700;
	font-display: auto;
	font-style: normal;
}
// Roboto Bold Italic
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-display: auto;
	font-style: italic;
}
// Roboto Black
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-display: auto;
	font-style: normal;
}
// Roboto Black Italic
@font-face
{
	font-family: 'Roboto';
	src: url(#{$path-fonts} + '/Roboto/Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-display: auto;
	font-style: italic;
}