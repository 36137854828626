.show-sticky-only
{
    display: none;
}
.plan-list-header-sticky
{
    position: fixed;
    top: calc($header-height + $header-border-bottom-height-sm);
    left: 50%;
    transform: translateX(-50%);
    
    @include media-breakpoint-up(lg)
    {
        top: calc($header-height + $header-border-bottom-height);
    }
    
    .show-sticky-only
    {
        display: block;
    }
    .hide-sticky
    {
        display: none;
    }
}