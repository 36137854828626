// Precision brand colors
@each $color, $value in $colors-precision
{
	.color-precision-#{$color}
	{
		color: $value !important;
	}
	// border
	.border-color-precision-#{$color}
	{
		border-color: $value !important;
	}
	// background
	.bg-color-precision-#{$color}
	{
		background-color: $value !important;
	}
}

// Grey - light
.color-grey-l1
{
	color: $color-grey-light-1;
}
.color-grey-l2
{
	color: $color-grey-light-2;
}
.color-grey-l3
{
	color: $color-grey-light-3;
}

// Grey - dark
.color-grey-d1
{
	color: $color-grey-dark-1;
}
.color-grey-d2
{
	color: $color-grey-dark-2;
}
.color-grey-d3
{
	color: $color-grey-dark-3;
}
// Standard colors
.color-primary
{
	color: $primary;
}
.color-success
{
	color: $success;
}
.color-danger
{
	color: $danger;
}